.table-body {
  td {
    border-bottom: solid var(--gray200) 0.1rem;
    color: var(--text);
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.7rem;
    text-align: start;
    white-space: nowrap;
  }

  tr:hover td {
    background-color: var(--gray200);
  }
}
