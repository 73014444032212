.checkbox-section {
  display: flex;
  align-items: center;
  flex: 1;

  width: 100%;
}

.checkbox-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: var(--text);
  font-weight: 500;

  &:hover input ~ span {
    border-color: var(--gray500);
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: var(--primary);
  }

  input:checked ~ span:after {
    display: block;
  }

  input:focus ~ span {
    border-color: var(--primary);
  }

  /* CHECK MARKER */
  span:after {
    width: 4px;
    height: 8px;
    margin-bottom: 0.2rem;
    border: solid var(--white);
    border-width: 0 0.15rem 0.15rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    min-width: 20px;
    margin-right: 0.25rem;
    border: 1px solid var(--gray400);
    border-radius: 0.25rem;
    background-color: var(--white);

    &:after {
      content: '';
      display: none;
    }
  }
}
