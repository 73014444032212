@import 'styles/utils/fields.utilities.scss';

.textarea-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.textarea-field {
  display: flex;
  align-items: center;

  border: 1px solid var(--gray400);
  border-radius: 0.25rem;
  color: var(--gray800);
  font-size: 1rem;
  font-weight: 500;

  overflow: hidden;
  outline: 0;

  width: 100%;
  min-height: 120px;
  padding: 0.5rem;

  resize: vertical;

  &:not(:disabled):hover {
    border-color: var(--gray500);
  }

  &::placeholder {
    color: var(--gray300);
  }

  &:focus {
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--gray300);
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
  }

  &::-moz-placeholder {
    opacity: 1;
  }
}
