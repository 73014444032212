@use 'styles/breakpoints.scss';

.not-found-section {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  width: 100%;
  height: 100%;

  figure {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoints.and-up(lg) {
    grid-template-columns: 1fr 1fr;

    figure {
      width: 40rem;
      height: 40rem;
    }
  }
}

.not-found-description {
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--primary);
    font-size: 9rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    color: var(--text);
    font-size: 1.5rem;
    font-weight: 500;
  }

  button {
    max-width: 250px;
    margin-top: 5rem;
  }
}
