@use 'sass:map';
@use 'styles/typography.scss';

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--gray100);
  border: 1px solid var(--gray300);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  padding: 1rem;

  i {
    font-size: 0;
  }
}

.card-content {
  h6 {
    color: var(--text-light);
    font-size: map.get(typography.$sizes, 'b1');
    font-weight: map.get(typography.$weights, 'b1');
  }

  h4 {
    color: var(--text);
    font-size: map.get(typography.$sizes, 'h4');
    font-weight: map.get(typography.$weights, 'h4');
    line-height: 2.4rem;
  }
}
