@use 'sass:map';
@use 'styles/typography.scss';
@use 'styles/breakpoints.scss';

.shortcut-card-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @include breakpoints.and-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoints.and-up(lg) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.shortcut-card {
  background: var(--white);
  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem 0.5rem;
  transition: background 0.2s, transform 0.2s;

  &:hover {
    background: var(--gray100);
    cursor: pointer;
    transform: scale(1.03);
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    color: var(--text);
    text-align: center;

    figure {
      background: var(--gray300);
      border-radius: 50%;
      font-size: 0;
      padding: 1rem;
    }
  }
}
