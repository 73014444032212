@use 'sass:map';
@use 'styles/typography.scss';

.filter-pedidos-container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1rem;
}

.filter-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  button {
    background-color: var(--gray200);
    border: 0;
    border-radius: 0.875rem;
    color: var(--text-light);
    font-size: map.get(typography.$sizes, 'b4');
    font-weight: map.get(typography.$weights, 'b4');
    padding: 0.5rem 0.675rem;
    transition: background 0.2s;

    &:hover {
      background: var(--gray300);
    }

    &.active-filter {
      background: var(--primary);
      color: var(--white);

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}
