@use 'styles/breakpoints.scss';

.profile-container {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  @include breakpoints.and-up(xl) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 6rem;
    place-items: flex-start;
    padding: 4rem 3rem 0 3rem;
  }
}
