@use 'styles/breakpoints.scss';

.pedido-materiais-fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.save-button {
  @include breakpoints.and-up(md) {
    max-width: 200px;
    margin-left: auto;
  }
}
