@use 'styles/breakpoints.scss';

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  width: 100%;
  height: 100%;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background-color: var(--primary);

  position: absolute;
  top: 10;
  left: 10;

  @include breakpoints.and-up(md) {
    width: 31.25rem;
    height: 28.75rem;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  position: relative;

  background: var(--white);
  border-radius: 0.313rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;

  max-width: 450px;

  width: 100%;

  z-index: 1;

  form {
    margin-bottom: 1rem;
  }

  @include breakpoints.and-up(md) {
    width: 450px;
    height: 500px;
  }
}

.img-container {
  display: grid;
  place-items: center;
}
