@use 'styles/breakpoints.scss';

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;

  list-style: none;

  width: 100%;
  height: 100%;

  a {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;

    border-radius: 0.3rem;
    color: var(--text);
    padding: 0.5rem;
    width: 100%;
    transition: background 0.2s;

    i {
      color: var(--primary);
      font-size: 0;
    }

    &:hover {
      background: var(--gray400);
    }
  }

  @include breakpoints.and-up(lg) {
    align-items: flex-start;
    justify-content: flex-start;
    width: initial;

    a {
      width: initial;
    }
  }
}
