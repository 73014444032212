@use 'styles/breakpoints.scss';

.profile-avatar-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 50%;
    cursor: pointer;
    margin-top: 1.25rem;
    overflow: hidden;
    position: relative;

    &:hover {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    input[type='file'] {
      display: none;
    }

    figure {
      display: flex;
      align-items: center;

      width: 15rem;
      height: 15rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include breakpoints.and-up(lg) {
        width: 21.875rem;
        height: 21.875rem;
      }
    }

    span {
      display: none;
      color: #fff;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.65);

      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;
    }
  }
}
