.fieldset-container {
  display: block;
  border-radius: 5px;
  border-color: var(--gray200);
  padding: 0.3rem 0.5rem;
  width: 100%;

  legend {
    font-size: 1rem;
    font-weight: 500;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem 1.25rem;
    width: 100%;
  }
}

.break-page {
  break-inside: avoid;
}
