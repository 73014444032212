@use '../colors.scss';

$all_colors: (
  white: colors.$white,
  gray100: colors.$gray100,
  gray200: colors.$gray200,
  gray300: colors.$gray300,
  gray400: colors.$gray400,
  gray500: colors.$gray500,
  gray700: colors.$gray700,
  gray800: colors.$gray800,
  blue: colors.$blue,
  blueLight: colors.$blueLight,
  green: colors.$green,
  orange: colors.$orange,
  red: colors.$red,
  purpleLight: colors.$purpleLight,
  primary: colors.$primary,
  secondary: colors.$secondary,
  info: colors.$info,
  success: colors.$success,
  warning: colors.$warning,
  error: colors.$error,
  title: colors.$title,
  text: colors.$text,
  textLight: colors.$textLight,
);

@each $color, $value in $all_colors {
  .#{'' + $color} {
    color: $value;
  }

  .bg-#{'' + $color} {
    background-color: $value;
  }

  .hover-#{'' + $color} {
    &:hover {
      color: $value !important;
    }
  }
}
