@use 'sass:map';
@use 'styles/typography.scss';

.bar-chart-container {
  position: relative;
  padding: 0.675rem;

  width: 100%;
  height: 550px;

  * {
    transition: all 0.2s;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  & text[dominant-baseline='central'],
  & text[text-anchor='middle'] {
    font-size: map.get(typography.$sizes, 'b4') !important;
    font-weight: bold;
  }

  & text {
    font-size: 1rem !important ;
  }
}
