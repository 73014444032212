// Literal Colors

$white: #ffffff;
$gray100: #f8f9fa;
$gray200: #e9ecef;
$gray300: #dee2e6;
$gray400: #ced4da;
$gray500: #adb5bd;
$gray700: #212540;
$gray800: #0f1225;

$blue: #2c57d9;
$blueLight: #2d99ff;
$green: #2fbd6a;
$orange: #ff8e31;
$red: #f83b3b;
$purpleLight: #7c81a4;

// Contextual Colors

$primary: $blue;
$secondary: $gray700;
$info: $blueLight;
$warning: $orange;
$success: $green;
$error: $red;

// Text Colors
$title: $gray800;
$text: $gray700;
$textLight: $purpleLight;
