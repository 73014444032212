.ultimas-atividades-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem 0 0.675rem 0.675rem;

  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray400);
    border-radius: 10px;
  }
}

.legend-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  margin-bottom: 1.5rem;
}

.legend-item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  text-transform: capitalize;
}

.ultimas-atividades-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    list-style: none;
    line-height: 1.4rem;
  }
}

.circle {
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
}

.incluir {
  background: var(--success);
}

.alterar {
  background: var(--warning);
}

.visualizar {
  background: var(--info);
}

.outros {
  background: var(--text);
}
