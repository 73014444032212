@use 'styles/breakpoints.scss';

.search-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  border-bottom: 1px solid var(--gray200);
  margin-top: 2rem;
  padding-bottom: 1rem;

  width: 100%;

  @include breakpoints.and-up(md) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.form-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 1rem;

  @include breakpoints.and-up(md) {
    flex-direction: row;
  }
}
