@import 'styles/utils/buttons.utilities.scss';

.input-file-section {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  transition: filter 0.2s, background 0.2s, color 0.2s;

  width: 100%;

  &:active {
    transform: translate(2px, 3px);
  }

  &:hover {
    filter: brightness(0.9);
  }

  input[type='file'] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: inherit;
    font-size: 1rem;
    font-weight: 500;

    padding: 0.5rem 1rem;
    width: 100%;
    height: 45px;

    &:active {
      transform: translate(2px, 3px);
    }
  }
}
