.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-size: 0;
  width: 3.125rem;
  height: 3.125rem;

  img {
    width: 100%;
    height: 100%;
  }
}
