@import 'styles/utils/fields.utilities.scss';

.select-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.select-field {
  display: flex;
  align-items: center;

  background: var(--gray50);
  border: 1px solid var(--gray400);
  border-radius: 0.25rem;
  color: var(--gray800);
  font-size: 1rem;
  font-weight: 500;

  overflow: hidden;
  outline: 0;

  width: 100%;
  height: 45px;
  padding: 0 0.5rem;

  &:hover {
    border-color: var(--gray500);
  }

  &:focus {
    border: 1px solid var(--primary);
  }

  &::placeholder {
    color: var(--gray300);
  }

  option {
    border-radius: 0;
  }
}
