@use 'sass:map';
@use 'styles/typography.scss';

.print-validacao-section {
  display: flex;
  flex-direction: column;

  p {
    display: flex;
    flex-direction: column;
    width: max-content;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.print-validacao-container {
  display: flex;
  column-gap: 0.25rem;
}

.print-validacao-button {
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 1px solid var(--primary);
  border-radius: 3px;
  color: var(--primary);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b4');
  line-height: 0.5rem;
  height: 16px;
  width: 18px;
}
