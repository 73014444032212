@use 'styles/breakpoints.scss';

.modal-confirm-frame-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(20, 44, 75, 0.5);
  padding: 1rem;

  min-width: 280px;

  button:focus {
    border: 2px solid black;
  }

  @include breakpoints.and-up(md) {
    padding: 2rem 3rem;
  }
}

.modal-confirm-frame-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @include breakpoints.and-up(md) {
    flex-direction: row;
  }
}
