.icon-button-container {
  background: transparent;
  border: 0;
  font-size: 0;
  color: var(--gray600);
  transition: color 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
