@use 'sass:map';
@use 'styles/typography.scss';

.table-wrapper-container {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--gray200);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 6px;
  }
}

.table-container {
  border-spacing: 0px;
  margin-top: 1rem;
  width: 100%;
}
