@use 'styles/breakpoints.scss';

.anexo-preview-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoints.and-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    display: flex;
    position: relative;

    &:hover {
      div {
        opacity: 1;
      }
    }
  }
}

.anexo-preview-hover {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 0.5rem;

  background: rgba(0, 0, 0, 0.5);
  color: var(--gray100);
  padding: 0.675rem;
  opacity: 0;
  transition: opacity 0.2s;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.anexo-preview-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background: var(--gray200);
  border: 1px solid var(--gray300);
  border-radius: 0.25rem;
  color: var(--text);
  padding: 0.675rem;

  width: 100%;
  height: 100%;

  p {
    word-break: break-all;
  }
}

.anexo-preview-image {
  background: var(--gray200);
  border: 1px solid var(--gray300);
  border-radius: 0.25rem;
  overflow: hidden;

  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
