.text-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: capitalize;

  span {
    text-transform: initial;
  }
}
