@use 'sass:map';
@use '../styles/typography.scss';

.logs-table-container-wrapper {
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--gray200);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 6px;
  }
}

.logs-table-container {
  border-spacing: 0px;
  margin-top: 1rem;
  width: 100%;
}

.logs-table-head {
  th {
    border-bottom: solid var(--gray200) 0.1rem;
    color: var(--text);
    font-size: map.get(typography.$sizes, 'h6');
    font-weight: map.get(typography.$weights, 'h6');
    padding: 1.25rem 0.7rem;
    text-align: start;
    white-space: nowrap;
  }

  .sortable {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }
}

.logs-table-body {
  td {
    border-bottom: solid var(--gray200) 0.1rem;
    color: var(--text);
    font-size: map.get(typography.$sizes, 'b1');
    font-weight: map.get(typography.$weights, 'b1');
    padding: 0.7rem;
    text-align: start;
    white-space: nowrap;
  }

  tr:hover td {
    background-color: var(--gray200);
  }
}

.logs-table-user-avatar {
  border-radius: 50%;

  width: 2.625rem;
  height: 2.625rem;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.incluir {
  color: var(--success) !important;
}

.alterar {
  color: var(--warning) !important;
}

.visualizar {
  color: var(--info) !important;
}

.outros {
  color: var(--text) !important;
}
