.icon-link-container {
  display: flex;

  i {
    font-size: 0;
  }

  a {
    background: transparent;
    border: 0;
    font-size: 0;
    color: var(--color-gray600);
    transition: color 0.2s;

    &:hover {
      color: var(--primary);
    }
  }
}

.is-disabled {
  opacity: 0.5;
  cursor: default;
}
