.ranking-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  padding: 0.675rem 0 0.675rem 0.675rem;

  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray400);
    border-radius: 10px;
  }
}

.ranking-row {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.ranking-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
