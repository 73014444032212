.consulta-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;

  > button {
    font-size: 1.25rem;
    height: 80px;
    max-width: 280px;
  }
}

.consulta-nao-realizada {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.25rem;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.consulta-em-andamento {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  margin: 4.25rem 0;
}

.consulta-realizada {
  margin: 4rem 0;
  text-align: center;
}

.consulta-erro {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.25rem;
  text-align: center;
  margin: 4.25rem 0;
}
