@use 'styles/breakpoints.scss';

.menu-mobile-container {
  position: fixed;
  bottom: 1rem;
  left: 0;
  padding: 0 0.5rem;
  width: 100%;
  z-index: 10000;

  @include breakpoints.and-up(lg) {
    visibility: hidden;
    width: 0;
    height: 0;
    display: none;
  }
}

.tab-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: white;
  border-radius: 0.313rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0.75rem 0.5rem;
  width: 100%;

  position: relative;
}

.tab-option {
  background: transparent;
  border: 0;
  font-size: 0;
  color: var(--primary);
}

.home-button {
  background: var(--secondary);
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  padding: 0.75rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  svg {
    color: var(--white);
  }
}

@keyframes overlayOpacity {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.menu-mobile-overlay {
  display: grid;
  place-items: center;

  animation: overlayOpacity 0.3s ease-in;
  background: rgba(24, 25, 45, 0.5);

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  cursor: default;
  z-index: 99;

  @include breakpoints.and-up(lg) {
    visibility: hidden;
    width: 0;
    height: 0;
    position: initial;
  }
}

.tab-bar-nav {
  background: var(--white);
  border-radius: 0.313rem 0.313rem 0 0;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.25);
  padding: 0 0.3rem;

  position: absolute;
  bottom: -500px;
  left: 50%;
  transform: translateX(-50%);

  transition: all 0.4s;
  visibility: hidden;
  width: calc(100% - 1rem);
  min-height: 500px;
}

.is-nav-open {
  bottom: 2.95rem;
  visibility: visible;
}

.tab-bar-nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--gray300);
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  figure {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}

.hide-nav-button {
  background: transparent;
  border: 0;
  color: var(--primary);
  font-size: 0;
}

.logout-button {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  background: transparent;
  border: 0;
  color: var(--text);
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  width: 100%;

  svg {
    color: var(--primary);
  }
}
