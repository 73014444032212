.tooltip-container {
  font-size: 0;
  position: relative;

  span {
    display: none;
    background: var(--secondary);
    border-radius: 6px;
    color: var(--white);
    text-align: center;

    width: 160px;
    padding: 8px;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    white-space: break-spaces;
    z-index: 2;

    &::before {
      content: '';
      border-style: solid;
    }
  }

  &:hover span {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.top {
  position: absolute;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  &::before {
    border-width: 6px 6px 0;
    border-top-color: var(--secondary);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bottom {
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  &::before {
    border-width: 0 6px 6px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--secondary);
    border-left-color: transparent;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.left {
  position: absolute;
  top: 50%;
  right: calc(100% + 12px);
  transform: translateY(-50%);

  &::before {
    border-width: 6px 0 6px 6px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--secondary);

    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
}

.right {
  position: absolute;
  top: 50%;
  left: calc(100% + 12px);
  transform: translateY(-50%);

  &::before {
    border-width: 6px 6px 6px 0;
    border-top-color: transparent;
    border-right-color: var(--secondary);
    border-bottom-color: transparent;
    border-left-color: transparent;

    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }
}
