@use 'styles/breakpoints.scss';

.layout-container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.blue-background {
  display: flex;
  background: var(--primary);
  width: 100%;
  height: 65px;
}

.layout-wrapper-container {
  display: flex;
  flex-direction: column;

  @include breakpoints.and-down(md) {
    flex-direction: column-reverse;
    padding: 0 0.5rem;
  }
}

.main-content-container {
  background: var(--white);
  border-radius: 0.313rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0.675rem;
  margin: -2rem 0 7rem 0;
  width: 100%;
  height: 100%;

  @include breakpoints.and-up(md) {
    padding: 1rem;
  }

  @include breakpoints.and-up(lg) {
    padding: 1.75rem;
    margin: 2.75rem 0;
  }
}

.main-content-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  @include breakpoints.and-up(md) {
    align-items: center;
    flex-direction: row;
  }
}

.main-content-components-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  @include breakpoints.and-up(md) {
    flex-direction: row;
    width: max-content;

    a,
    button {
      width: max-content;
    }
  }
}

.main-divider {
  border: 1px solid var(--gray300);
  margin: 0.5rem 0 2rem 0;
  width: 100%;
}
