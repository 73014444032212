.user-avatar-container {
  border-radius: 50%;

  width: 2.625rem;
  height: 2.625rem;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
