.legend-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  padding: 2rem 0.675rem 1rem 0.675rem;
  width: 100%;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.circle {
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
}
