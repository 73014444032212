@use 'sass:map';
@use 'styles/typography.scss';

.filter-button-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.remove-filter-button {
  border: 0;
  background: transparent;
  color: var(--purple-light);
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b4');
  transition: color 0.2s;

  &:hover {
    color: var(--blue);
  }
}

.is-filtering {
  color: var(--primary);
}
