@use 'styles/colors.scss';

.badge-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.3rem;
}

.badge-primary {
  background: lighten(colors.$primary, 45%);
  border: 1px solid colors.$primary;
  color: colors.$primary;
}

.badge-secondary {
  background: lighten(colors.$secondary, 73%);
  border: 1px solid colors.$secondary;
  color: colors.$secondary;
}

.badge-info {
  background: lighten(colors.$info, 37%);
  border: 1px solid colors.$info;
  color: colors.$info;
}

.badge-success {
  background: lighten(colors.$success, 50%);
  border: 1px solid colors.$success;
  color: colors.$success;
}

.badge-warning {
  background: lighten(colors.$warning, 37%);
  border: 1px solid colors.$warning;
  color: colors.$warning;
}

.badge-error {
  background: lighten(colors.$error, 37%);
  border: 1px solid colors.$error;
  color: colors.$error;
}
