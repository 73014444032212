@keyframes modalScale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal-frame {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;

  animation: modalScale 0.3s ease-in-out;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0 0 12px rgba(20, 44, 75, 0.5);

  padding: 1rem;
  max-width: 600px;
  width: 100%;

  transform: scale(1);
  z-index: 100;

  > button {
    max-width: 180px;
    margin-top: 1rem;
  }
}
