.info-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1rem;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  figure {
    font-size: 0;
    width: 2.5rem;
    height: 2.5rem;

    img {
      user-select: none;
      border-radius: 50%;
      object-fit: cover;

      width: 100%;
      height: 100%;
    }
  }
}
