@use 'styles/breakpoints.scss';

.submenu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  list-style: none;
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 3;

  @include breakpoints.and-up(lg) {
    align-items: flex-start;
    justify-content: flex-start;
    width: initial;
  }
}

.submenu-item {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  border-radius: 0.3rem;
  color: var(--blue);
  padding: 0.5rem;
  width: 100%;
  transition: background 0.2s;

  &:hover {
    background: var(--gray300);
  }

  p {
    color: var(--text);
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;

    svg {
      transition: transform 0.2s;
    }
  }
}

.rotate-icon {
  svg {
    transform: rotate(90deg);
  }
}

.submenu-list-container {
  display: none;

  background: var(--gray200);
  border: 1px solid var(--gray400);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  width: max-content;

  @include breakpoints.and-down(md) {
    background: transparent;
    border-radius: 0.3rem;
    box-shadow: none;
    border: 0;
    width: 100%;
  }

  @include breakpoints.and-up(lg) {
    position: absolute;
    top: 2.85rem;
    left: 0;
  }
}

.submenu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 0;
  width: 100%;

  li {
    width: 100%;

    a {
      padding: 0.5rem;
      border-radius: 0;
      width: 100%;
    }
  }

  @include breakpoints.and-down(md) {
    padding: 0;

    li a {
      padding-left: 1.75rem;
      border-radius: 0.3rem;
    }
  }
}

.drop-submenu {
  display: flex;
}
