.table-head {
  th {
    border-bottom: solid var(--gray200) 0.1rem;
    color: var(--text);
    font-size: 1rem;
    font-weight: bold;
    padding: 1.25rem 0.7rem;
    text-align: start;
    white-space: nowrap;
  }

  .sortable {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }
}
