@use 'styles/colors.scss';
@use 'styles/breakpoints.scss';

.error-details-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  max-height: 500px;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  p {
    margin: 0.5rem 0;
  }

  button {
    max-width: 180px;
  }

  code {
    background: var(--gray100);
    border: 1px solid var(--gray300);
    border-radius: 0.313rem;
    font-size: 0.875rem;

    padding: 1rem 0.5rem;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(20, 44, 75, 0.5);
      border-radius: 10px;
    }
  }
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;

  background-color: lighten(colors.$green, 50%);
  border: 1px solid var(--success);
  border-radius: 2rem;
  color: var(--success);
  padding: 0.5rem 1rem;
  outline: 0;
}

.is-copied {
  background-color: var(--green);
  color: var(--white);
}
