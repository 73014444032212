@use 'sass:map';
@use 'styles/typography.scss';
@use 'styles/breakpoints.scss';

.novo-pedido-section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.tipo-pessoa-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    background: var(--gray100);
    border: 1px solid var(--gray300);
    border-radius: 4px;
    color: var(--primary);
    font-size: map.get(typography.$sizes, 'h4');
    font-weight: map.get(typography.$weights, 'h4');
    padding: 0.5rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.96);
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;

    button {
      figure {
        width: 20rem;
        height: 20rem;
      }
    }
  }
}
