.user-profile-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;

  summary {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: var(--blue);
    cursor: pointer;
    list-style: none;
    padding-left: 0.5rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.85);
    }

    img {
      user-select: none;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.dropdown-caret {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.user-profile-menu {
  background: var(--gray200);
  border: 1px solid var(--gray400);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: var(--text);

  position: absolute;
  top: 100%;
  right: 0;
  left: auto;

  margin-top: 0.3rem;
  padding: 0.5rem 0;
  width: 180px;
  z-index: 100;

  &::before {
    display: inline-block;
    position: absolute;
    top: -16px;
    right: 9px;
    left: auto;
    border: 8px solid transparent;
    border-bottom-color: var(--gray400);
    content: '';
  }

  &::after {
    display: inline-block;
    position: absolute;
    top: -14px;
    right: 10px;
    left: auto;
    border: 7px solid transparent;
    border-bottom-color: var(--gray200);
    content: '';
  }

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
    list-style: none;
    padding: 0.5rem 1rem;
    transition: background 0.2s;

    &:hover {
      background: var(--gray400);
    }

    &:last-child {
      margin-top: 0.5rem;
    }

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--text);
      background-color: transparent;
      border: 0;
      width: 100%;
    }

    svg {
      color: var(--primary);
    }
  }
}

.menu-divider {
  background: var(--gray400);
  margin: 0.3rem;
  height: 1px;
}
