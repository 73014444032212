@use 'styles/breakpoints.scss';

.flex-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @include breakpoints.and-up(md) {
    flex-direction: row;
  }
}
