@use './breakpoints';
@use './colors.scss' as *;
@import './utils/text.utilities.scss';
@import './utils/grid.utilities.scss';
@import './utils/colors.utilities.scss';
@import './utils/typography.utilities.scss';
@import '../../node_modules/normalize.css/normalize.css';

:root {
  --white: #{$white};
  --gray100: #{$gray100};
  --gray200: #{$gray200};
  --gray300: #{$gray300};
  --gray400: #{$gray400};
  --gray500: #{$gray500};
  --gray700: #{$gray700};
  --gray800: #{$gray800};
  --blue: #{$blue};
  --blue-light: #{$blueLight};
  --green: #{$green};
  --orange: #{$orange};
  --red: #{$red};
  --purple-light: #{$purpleLight};

  --primary: #{$blue};
  --secondary: #{$gray700};
  --info: #{$blue};
  --warning: #{$orange};
  --success: #{$green};
  --error: #{$red};

  --title: #{$gray800};
  --text: #{$gray700};
  --text-light: #{$purpleLight};
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div#app {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  background-color: var(--gray200);
}

html {
  font-size: 14px;

  @include breakpoints.and-down(sm) {
    font-size: 87.5%;
  }

  @include breakpoints.and-up(md) {
    font-size: 93.74%;
  }

  @include breakpoints.and-up(lg) {
    font-size: 100%;
  }
}

body {
  background: var(--gray100);
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

input,
textarea,
button,
a {
  font-family: 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.centralized-container {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
