@use 'styles/breakpoints.scss';

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.form-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;
}
