@use 'sass:map';
@use '../typography.scss';
@use '../colors.scss';

.heading1 {
  font-size: map.get(typography.$sizes, 'h1');
  font-weight: map.get(typography.$weights, 'h1');
}

.heading2 {
  font-size: map.get(typography.$sizes, 'h2');
  font-weight: map.get(typography.$weights, 'h2');
}

.heading3 {
  font-size: map.get(typography.$sizes, 'h3');
  font-weight: map.get(typography.$weights, 'h3');
}

.heading4 {
  font-size: map.get(typography.$sizes, 'h4');
  font-weight: map.get(typography.$weights, 'h4');
}

.heading5 {
  font-size: map.get(typography.$sizes, 'h5');
  font-weight: map.get(typography.$weights, 'h5');
}

.heading6 {
  font-size: map.get(typography.$sizes, 'h6');
  font-weight: map.get(typography.$weights, 'h6');
}

.body1 {
  font-size: map.get(typography.$sizes, 'b1');
  font-weight: map.get(typography.$weights, 'b1');
}

.body2 {
  font-size: map.get(typography.$sizes, 'b2');
  font-weight: map.get(typography.$weights, 'b2');
}

.body3 {
  font-size: map.get(typography.$sizes, 'b3');
  font-weight: map.get(typography.$weights, 'b3');
}

.body4 {
  font-size: map.get(typography.$sizes, 'b4');
  font-weight: map.get(typography.$weights, 'b4');
}

.caption1 {
  font-size: map.get(typography.$sizes, 'caption1');
  font-weight: map.get(typography.$weights, 'caption1');
}

.caption2 {
  font-size: map.get(typography.$sizes, 'caption2');
  font-weight: map.get(typography.$weights, 'caption2');
}
