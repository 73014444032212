.step-title-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 2rem;
}

.go-back-button {
  border: 0;
  background: transparent;
  color: var(--primary);
  font-size: 0;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.85);
  }
}
