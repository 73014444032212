@use 'sass:map';
@use 'styles/typography.scss';

.breadcrumb-list {
  display: flex;
  gap: 0.3rem;
}

.breadcrumb-item {
  list-style: none;
  color: var(--title);
  font-size: map.get(typography.$sizes, 'h3');
  font-weight: map.get(typography.$weights, 'h3');

  &:not(:last-child)::after {
    content: '/';
    margin-left: 0.3rem;
  }

  &:last-child {
    color: var(--text-light);
  }

  &:first-child {
    color: var(--title);
  }

  a {
    color: inherit;

    &:visited {
      color: inherit;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
