.fallback-error-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  h1 {
    color: var(--primary);
    font-size: 3rem;
  }

  button {
    max-width: 180px;
  }

  a {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;

    color: var(--primary);
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 2rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(1.25);
    }

    &:visited {
      color: var(--primary);
    }
  }

  code {
    background: var(--gray100);
    border: 1px solid var(--gray300);
    border-radius: 0.313rem;
    font-size: 0.875rem;

    padding: 1rem 0.5rem;
    overflow-x: auto;
    width: 100%;
  }
}
