.is-errored {
  border-color: var(--error) !important;
}

.is-filled {
  border-color: var(--success) !important;
}

.is-focused {
  border-color: var(--gray-400) !important;
}
