@use 'sass:map';
@use 'styles/typography.scss';

.validacao-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.validacao-button-list {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
}

.validacao-button {
  background: transparent;
  border: 1px solid var(--primary);
  border-radius: 4px;
  color: var(--primary);
  font-size: map.get(typography.$sizes, 'b2');
  font-weight: map.get(typography.$weights, 'b2');
  padding: 0.75rem 0.5rem;
  width: 100%;
  transition: background 0.2s, color 0.2s;

  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
}

.button-active {
  background-color: var(--primary);
  color: var(--white);
}
