.signature-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 100%;

  button {
    margin-top: 1rem;
    width: max-content;
  }
}

.signature-field {
  display: flex;
  justify-content: center;
  width: 100%;
}

.clear-button {
  padding: 0.3rem;
  width: 100%;
}
