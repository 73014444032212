@use 'styles/breakpoints.scss';

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;

  @include breakpoints.and-up(md) {
    flex-direction: row;
  }
}

.pagination-per-page-container {
  display: flex;
  align-items: center;
  column-gap: 0.675rem;

  select {
    border-radius: 0.3rem;
    border-color: var(--gray400);
    color: var(--text);

    &:hover {
      border-color: var(--gray500);
    }
  }
}

.pagination-buttons-container {
  display: flex;
  align-items: center;

  button {
    font-size: 0;
    border: 0;
    border-radius: 50%;
    background: transparent;
    color: var(--text);
    padding: 0.5rem;
    transition: background 0.2s;

    &:not(:disabled):hover {
      background: var(--gray200);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
