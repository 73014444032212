@use 'sass:map';
@use 'styles/typography.scss';

.table-footer {
  background-color: var(--gray100);

  td {
    color: var(--text);
    font-size: map.get(typography.$sizes, 'b2');
    font-weight: map.get(typography.$weights, 'b2');
    padding: 0.875rem 0.7rem;
    white-space: nowrap;
  }
}
