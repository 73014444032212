.consulta-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;

  margin: 4rem 0;
  text-align: center;

  a {
    color: var(--text);
    font-size: 1.25rem;
    font-weight: 500;
    transition: 0.2s color;

    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }
  }
}
