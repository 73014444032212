@use 'styles/breakpoints.scss';

.dashboard-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4 {
    border-bottom: 1px solid var(--gray300);
    padding-bottom: 0.3rem;
  }
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  figure {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 50%;
    font-size: 0;

    p {
      text-transform: capitalize;
    }

    a {
      color: var(--text);

      &:visited {
        color: inherit;
      }

      &:hover {
        color: var(--primary);
      }
    }

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
    }
  }
}

.shortcut-card {
  background: var(--white);
  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem 0.5rem;
  transition: background 0.2s, transform 0.2s;

  @include breakpoints.and-up(md) {
    width: 12.5rem;
    height: 8.75rem;
  }

  &:hover {
    background: var(--gray100);
    cursor: pointer;
    transform: scale(1.03);
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    color: var(--text);
    text-align: center;

    figure {
      background: var(--gray300);
      border-radius: 50%;
      font-size: 0;
      padding: 1rem;
    }
  }
}

.illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  img {
    width: 18rem;
    height: 13.5rem;
  }

  @include breakpoints.and-up(md) {
    img {
      width: 25rem;
      height: 17.5rem;
    }
  }
}
