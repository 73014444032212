.see-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;

  background: transparent;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text);
  margin: 2rem auto 0 auto;
  transition: color 0.2s;

  &:not(:disabled):hover {
    color: var(--primary);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
