@use 'styles/breakpoints.scss';

.navbar-list-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @include breakpoints.and-up(lg) {
    flex-direction: row;
    height: 100%;
  }
}

.navbar-list-open {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);

  @include breakpoints.and-up(lg) {
    position: initial;
    flex-direction: row;
  }
}
