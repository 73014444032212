@use 'styles/breakpoints.scss';

.default-dashboard-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4 {
    border-bottom: 1px solid var(--gray300);
    padding-bottom: 0.3rem;
  }
}

.default-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  figure {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 50%;
    font-size: 0;

    p {
      text-transform: capitalize;
    }

    a {
      color: var(--text);

      &:visited {
        color: inherit;
      }

      &:hover {
        color: var(--primary);
      }
    }

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
    }
  }
}

.illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  img {
    width: 18rem;
    height: 13.5rem;
  }

  @include breakpoints.and-up(md) {
    img {
      width: 25rem;
      height: 17.5rem;
    }
  }
}
