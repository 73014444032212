.acessos-list-container {
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    li {
      list-style: none;
    }
  }
}

.is-parent-child {
  margin-left: 2rem;
}

.has-parent {
  margin-left: 1rem;
}
