@use 'sass:map';
@use 'styles/breakpoints.scss';

.text-column {
  display: flex;
  flex-direction: column;
  line-height: 1.4rem;
}

.card-container {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--gray100);
  border-bottom: 1px solid var(--gray200);
  padding: 0.5rem;
}

.card-main {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem 0.5rem;

  button {
    margin-top: 0.5rem;
  }

  @include breakpoints.and-up(lg) {
    button {
      margin-left: auto;
      width: max-content;
    }
  }
}

.pedido-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem 3rem;
}
