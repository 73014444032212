@use 'styles/breakpoints.scss';

.fieldset-container {
  border: 1px solid var(--gray400);
  padding: 1rem;

  border-radius: 6px;

  width: 100%;

  legend {
    background-color: var(--text);
    color: var(--white);
    font-weight: 500;

    border-radius: 6px;

    padding: 0.5rem 1rem;
  }
}

.fieldset-content {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;

  width: 100%;
}
