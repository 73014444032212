@use 'styles/breakpoints.scss';

.finish-step-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  border-top: 1px solid var(--gray200);
  margin-top: 1rem;
  padding-top: 1rem;

  width: 100%;

  @include breakpoints.and-up(md) {
    justify-content: space-between;

    button {
      margin-left: auto;
      width: 180px;
    }
  }
}
