.meus-pedidos-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.meus-pedidos-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
