@use 'sass:map';
@use 'styles/breakpoints.scss';
@use 'styles/typography.scss';

.help-section-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.help-section-description {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  text-align: center;
  width: 100%;

  a:hover {
    text-decoration: underline;
  }
}

.help-button-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  margin-top: 2rem;
  width: 100%;

  @include breakpoints.and-up(sm) {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    background: var(--gray100);
    border: 1px solid var(--gray300);
    border-radius: 8px;
    color: var(--text);
    font-size: map.get(typography.$sizes, 'b2');
    font-weight: map.get(typography.$weights, 'b2');
    padding: 1rem;
    min-width: 150px;
    transition: background 0.2s;

    &:hover {
      background-color: var(--gray200);
    }
  }
}

.divider {
  border: 0;
  background: var(--gray300);
  height: 1px;
  margin: 2rem 0;
  width: 100%;
}

.help-contact-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
}

.help-footer-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  padding: 1rem 0;
  width: 100%;

  @include breakpoints.and-up(md) {
    flex-direction: row;
    justify-content: center;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  text-align: center;
}
