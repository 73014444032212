@use 'sass:map';
@use 'styles/breakpoints.scss';

.text-row {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.text-column {
  display: flex;
  flex-direction: column;
  line-height: 1.4rem;
}

.card-container {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--gray100);
  border-bottom: 1px solid var(--gray200);
  padding: 0.5rem;
}

.card-main {
  padding: 1rem 0.5rem;
}

.pedido-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--gray200);
  margin: 1rem 0;
  padding-bottom: 0.5rem;
}

.pedido-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem 3rem;
  border-bottom: 1px solid var(--gray200);
  padding-bottom: 0.5rem;
}

.input-file {
  justify-self: center;

  @include breakpoints.and-up(lg) {
    width: 50%;
  }
}

.add-anexo-button {
  margin-top: 1rem;

  @include breakpoints.and-up(lg) {
    margin-left: auto;
    width: max-content;
  }
}
