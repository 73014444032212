@use 'sass:map';
@use '../styles/typography.scss';
@use '../styles/breakpoints.scss';

.planos-table {
  border-spacing: 0 0.5rem;
  margin-top: 1rem;
  width: 100%;

  tbody {
    background: var(--gray200);
  }

  th {
    color: var(--title);
    font-weight: 500;
    line-height: 1.5rem;
    padding: 1rem;
    text-align: left;
  }

  td {
    border: 0;
    color: var(--text);
    font-size: map.get(typography.$sizes, 'b2');
    font-weight: map.get(typography.$weights, 'b2');
    padding: 1rem;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      text-align: right;
      border-radius: 0 4px 4px 0;
    }
  }

  tfoot {
    background: var(--gray100);

    td {
      border-top: 1px solid var(--gray500);

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  @include breakpoints.and-up(md) {
    th,
    td {
      padding: 1rem 2rem;
    }
  }
}
