@use 'styles/breakpoints.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: navbar;
  margin-top: -2rem;

  width: 100%;

  @include breakpoints.and-down(md) {
    display: none;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.header-navbar-container {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  background: var(--white);
  border-radius: 0.313rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0.65rem;
  width: 100%;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

.navbar-open {
  display: flex;
}
