.consulta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-size: 1rem;
    font-weight: 500;

    height: 40px;
    padding: 0.5rem 1rem;

    transition: filter 0.2s, background 0.2s, color 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.consulta-result-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.consulta-result {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
