@use 'sass:map';
@use 'styles/breakpoints.scss';

.text-column {
  display: flex;
  flex-direction: column;
  line-height: 1.4rem;
}

.card-container {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--gray100);
  border-bottom: 1px solid var(--gray200);
  padding: 0.5rem;
}

.card-main {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 1rem 0.5rem;
}

.pedido-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem 3rem;
}

.input-multi-file {
  width: 100%;

  @include breakpoints.and-up(lg) {
    width: 30%;
  }
}

.divider {
  border: 0;
  background: var(--gray300);
  height: 1px;
  margin: 2rem 0;
  width: 100%;
}

.pedido-materiais-fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}
