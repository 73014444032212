.ranking-top-position {
  display: flex;
  align-items: center;
  gap: 1.25rem;

  figure {
    font-size: 0;

    img {
      width: 1.625rem;
      height: 1.625rem;
    }
  }

  div {
    line-height: 1.25rem;

    p {
      text-transform: capitalize;
    }
  }
}
