@import 'styles/utils/fields.utilities.scss';

.multi-select-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.multi-select-field {
  display: flex;
  align-items: center;

  background: var(--gray50);
  border: 1px solid var(--gray400);
  border-radius: 0.25rem;
  color: var(--gray800);
  font-size: 1rem;
  font-weight: 500;

  overflow: hidden;
  outline: 0;

  width: 100%;
  height: 45px;
  padding: 0 0.5rem;

  &:hover {
    border-color: var(--gray500);
  }

  &:focus {
    border: 1px solid var(--primary);
  }

  option {
    border-radius: 0;
  }
}

.selected-options-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.selected-option-item {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;

  background: var(--gray400);
  border-radius: 0.2rem;
  color: var(--gray50);
  margin: 0.3rem;
  padding-left: 0.375rem;

  button {
    display: flex;
    align-items: center;

    background: #fecaca;
    border: 0;
    border-radius: 0 0.2rem 0.2rem 0;
    color: #dc2626;
    padding: 0.2rem;

    &:hover {
      background: #fca5a5;
    }
  }
}
