@use 'sass:map';
@use '../../styles/typography.scss';

.label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  color: var(--gray700);
  font-size: map.get(typography.$sizes, 'b1');
  font-weight: map.get(typography.$weights, 'b2');
}
