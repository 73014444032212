.anexos-input-file {
  width: max-content;
}

.anexos-illustration {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
}
