@use 'styles/breakpoints.scss';

.telefone-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 4px;
    background: var(--red);
    color: var(--white);

    width: 45px;
    height: 45px;

    transition: filter 0.2s, background 0.2s, color 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @include breakpoints.and-up(md) {
    align-items: flex-end;
    flex-direction: row;
    gap: 0.675;
  }
}

.align-end {
  align-items: flex-end;
  flex: 1;
}
