@use 'sass:map';
@use 'styles/typography.scss';
@use 'styles/breakpoints.scss';

.tab-buttons-container {
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--color-gray200);
  padding: 0 0.5rem;

  @include breakpoints.and-up(lg) {
    flex-direction: row;
  }
}

.tab-button {
  background: transparent;
  border: 0;
  border-bottom: 3px solid transparent;
  color: var(--textLight);
  font-size: map.get(typography.$sizes, 'b2');
  font-weight: map.get(typography.$weights, 'b2');
  padding: 0.3rem 0.5rem;
  margin: 0 0.275rem;
  text-transform: capitalize;
  transition: border-color 0.2s, color 0.2s;

  &:hover {
    border-bottom-color: var(--primary);
    color: var(--title);
  }

  @media (max-width: 600px) {
    margin: 0.3rem 0;
  }
}

.active {
  border-bottom-color: var(--primary);
  color: var(--title);
}
