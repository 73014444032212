.card-container {
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray200);
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--gray100);
  border-bottom: 1px solid var(--gray200);
  padding: 1rem 0.75rem;
}

.card-content {
  background: var(--white);
}
