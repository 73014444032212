@use 'styles/breakpoints.scss';

@keyframes overlayOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-filter-overlay {
  display: grid;
  place-items: center;

  animation: overlayOpacity 0.2s ease-in-out;
  background: rgba(29, 24, 45, 0.5);

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 1rem;
  overflow-y: auto;
  transition: background 0.3s;
  z-index: 5;
}

.modal-filter-frame {
  display: flex;
  flex-direction: column;

  animation: modalScale 0.3s ease-in-out;

  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0 0 12px rgba(20, 44, 75, 0.5);

  max-width: 1140px;
  width: 100%;

  padding: 2rem;
  transform: scale(1);
  z-index: 100;
}

.modal-filter-frame-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: 0;
    background: transparent;
    font-size: 0;
    color: var(--gray500);
    transition: color 0.2s;

    &:hover {
      color: var(--gray700);
    }
  }
}

.modal-filter-frame-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
