@use 'styles/breakpoints.scss';

.preview-file-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoints.and-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.preview-file-item {
  display: flex;
  position: relative;

  button {
    background: rgba(255, 215, 215, 0.65);
    border: 0;
    border-radius: 50%;
    color: var(--color-red);
    font-size: 0;
    padding: 0.3rem;
    transition: background 0.2s;

    position: absolute;
    top: 0.3rem;
    right: 0.3rem;

    &:hover {
      background: #ffdfdf;
    }
  }
}

.preview-file-image {
  background: var(--gray200);
  border: 1px solid var(--gray300);
  border-radius: 0.25rem;
  overflow: hidden;

  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.preview-file-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  background: var(--gray200);
  border: 1px solid var(--gray300);
  border-radius: 0.25rem;
  color: var(--text);
  padding: 0.675rem;

  width: 100%;
  height: 100%;

  p {
    word-break: break-all;
  }
}
