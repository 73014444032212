@keyframes loadingRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 25, 45, 0.2);
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;

  &::before {
    content: '';
    width: 100px;
    height: 100px;

    animation: loadingRotation 1s linear infinite;
    background: transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid var(--primary);
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-radius: 50%;
  }
}
