@use 'styles/breakpoints.scss';

.anexos-input-file {
  width: max-content;
}

.anexos-image-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @include breakpoints.and-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoints.and-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  figure {
    background: var(--gray200);
    border: 1px solid var(--gray300);
    border-radius: 0.25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
