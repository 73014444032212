@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tab-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-content {
  display: flex;
  background: transparent;
  margin-top: 2rem;

  & > section {
    width: 100%;
  }
}

.animate {
  animation: opacityAnimation 0.5s ease-in-out;
}
