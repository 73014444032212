@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';

    animation: loadingAnimation 1s linear infinite;
    background: transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid var(--primary);
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-radius: 50%;
  }
}

.size-large::before {
  width: 100px;
  height: 100px;
}

.size-medium::before {
  width: 52px;
  height: 52px;
}

.size-small::before {
  width: 32px;
  height: 32px;
}
